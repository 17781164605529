import React from "react";
import { Route, Routes } from "react-router";
import Home from "./LandingPage/Home";
import ProductSlider from "./LandingPage/parts/ProducSlider";
import PayuMoney from "./LandingPage/PayuMoney";
import ThankYouPage from "./LandingPage/parts/Thankyou";
import NotFoundPage from "./LandingPage/parts/Notfound";
import Dummy from "./utils/Dummy";
const Main = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/thank-you"
          element={<ThankYouPage />}
        />
        <Route exact path="/404-Not-Found" element={<NotFoundPage />} />
        <Route exact path="/dummy" element={<Dummy/>} />
        <Route exact path="/pay" element={<PayuMoney />} />
      </Routes>
    </>
  );
};

export default Main;
